.upload-user-photo {
    margin-top: 3%;
    text-align: center;

    video {
        width: 100%;
    }
    .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    img {
        width: 100%;
    }
    .MuiFormControl-root {
        display: block;
        text-align: center;
    }
    .upload{
        text-align: left;
    }
    .hidden {
        display: none;
    }

    .image {
    }
}