.pass {

    .avatar {
        width: 64px;
        height: 64px;
        margin-right: 20px;
    }
    .pass-name {
        font-size: 1.0rem
    }
    .sector {
        font-weight: 500;
    }
    .bold {
        font-weight: bold
    }
    .date {
        font-weight: 500
    }
}

.pass.MuiListItem-gutters {
    padding-right: 4px;
    border-bottom: 1px solid #EEEEEE;

}