.pass-guests {
    .MuiChip-deleteIcon {
        font-size: 1.3rem;
        color: rgba(234, 0, 0, 0.84);
    }
    .MuiChip-deleteIcon:hover {
        color: rgba(212, 42, 42, 0.84);
    }
    .MuiChip-deletable{
        margin-right: 10px;;
    }
}