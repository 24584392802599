.CompanyStaffContent{
  font-family: "Gotham";

  .search-box-2{
    width: 98%;
    padding: 7px 1%;
    background-color: #ecebeb;
  }

  .search-box {
    margin-right: 0px;
    .MuiInputBase-input {
      font-size: 15px;
      padding: 6px;
    }
  }
  th.MuiTableCell-root.MuiTableCell-head {
      font-size: 14px;
  }
  .MuiTableCell-root {
      font-size: 13px;
      padding: 5px;
  }
  span.MuiButtonBase-root.MuiTableSortLabel-root,
  svg.MuiSvgIcon-root.colSort {
      right: 0px;
  }
  /*.MuiTablePagination-toolbar {
      padding-right: 70px;
  }*/

  .columnsContent{
    button{
        min-width: 0px;
        padding: 7px !important;
        width: 2.7em !important;
        display: inline-block;
        margin: auto;
    }
  }
  .MuiTab-textColorPrimary {
    color: #43a047;
    background-color: #FFF;
    border-radius: 25px 25px 0px 0px;
    margin-right: 7px;
    font-size: 18px;
    font-family: "Gotham";
  }
  .MuiTab-textColorPrimary.Mui-selected {
    color: #FFF;
    background-color: #43a047;
  }
  .MuiTab-wrapper{
    text-transform: initial;
  }
  .MuiButton-label{
    white-space: nowrap;
    font-family: "Gotham";
    font-size: 13px;
  }
  .block-user-staff.MuiGrid-container {
    width: 900px;
    padding: 1em;
  }
  .MuiAvatar-root{
    margin: auto;
  }

  .containerRight {
    flex-grow: 1;
    display: flex;
    justify-content: right;
    align-items: center;

    button{
      background-color: #FFF;
      padding: 5px 10px;
      margin-right: 4px;
      border: 1px solid #c3c3c3;
      color: #969696;
  
      &:hover {
        background-color: rgb(248, 248, 248);
      }
    }
  }

  
  @media only screen and (max-width: 960px) {
    .MuiTabs-root.pull-left {
      float: none;
    }
    .MuiTabs-indicator{
      display: none;
    }
  } 
}
.specialPermissions {
  > label {
    float: left;
  }
  .MuiFormControlLabel-label {
    text-align: left;
  }
}
.birthdate button.MuiButtonBase-root.MuiIconButton-root,
  .birthdate .MuiInput-underline.Mui-error:after{
    display: none;
  }