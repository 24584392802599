.ValidatePassesContent{
    display: flex;
    flex-direction: column;
    h1.title {
        font-size: 22px;
    }
    .search-box .MuiInputBase-input {
        font-size: 15px;
        padding: 6px;
    }
    th.MuiTableCell-root.MuiTableCell-head {
        font-size: 14px;
    }
    .MuiTableCell-root {
        font-size: 13px;
        padding: 5px;
    }
    span.MuiButtonBase-root.MuiTableSortLabel-root,
    svg.MuiSvgIcon-root.colSort {
        right: 0px;
    }
    .MuiTablePagination-toolbar {
        padding-right: 70px;
    }
    
    .MuiFab-primary {
        position: absolute;
        bottom: 1em;
        right: 3em;
        @media only screen and (max-width: 1366px) {
            bottom: 3em;
        }    
    }
    .columnsContent{
        white-space: nowrap;
        button{
            min-width: 0px;
            padding: 7px !important;
            width: 2.7em !important;
            display: inline-block;
            margin: auto;
        }
    }
        
}