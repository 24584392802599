.passes-block {
    .search {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 5px;

        input.MuiInputBase-input  {
            padding: 11px 14px;
            font-size: 14px;
            
        }
        label.MuiInputLabel-formControl {
            top: -7px;
        }

    }

    .noRow {
        text-align: center;
        color: #767676;
    }
}