.pass-detail {
    .radio {
        .MuiFormGroup-root {
            display: block;
        }
        legend {
            margin-bottom: 0;
        }
    }

    .allowed-times {
        margin-top: 15px;
        display: block;
    }
    .loader {
        text-align: center;
    }

    .submitButton{
        margin-left: 0px !important;
    }
}