.pass-request{
    .user{
        padding: 0;
        margin-bottom: 15px;

        .MuiAvatar-root{
            width: 60px;
            height: 60px;
        }

        .pass-name {
            margin-left: 10px;
        }
    }
    .radio {
        .MuiFormGroup-root {
            display: block;
        }
        legend {
            margin-bottom: 0;
        }
    }

    .MuiDivider-root {
        margin-bottom: 20px;
    }
    
}