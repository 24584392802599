.register {
  text-align: center;

  .info {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .field {
    flex: 1 !important;
    margin-top: 10px;
    .MuiTextField-root {
      width: 100%;
    }
  }
  .terms {
    margin-top: 15px;
  }

  .left {
    text-align: left;
  }

  .MuiFormControl-fullWidth {
    display: block;
  }
  .terms-error {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 0;
  }
  .box-psw {
    position: relative;
  }
  svg.MuiSvgIcon-root.show-psw {
    position: absolute;
    top: 33px;
    right: 8px;
    color: #737373;
    cursor: pointer;
  }
}
