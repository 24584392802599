.login {
  text-align: center;
  display: flex !important;
  align-items: center;
  min-height: 100vh;
  .container {
    width: 100%;
  }
  .submit {
    margin-top: 10px;
  }
  .logo {
    margin-bottom: 5px;
  }
  .forgot-password {
    margin-top: 5px;
  }
  .version {
    margin-top: 15px;
    font-size: 12px;
    color: #959595;
  }
  .alert {
    background-color: #ffd2d2;
  }
  .terms {
    margin-top: 15px;
    text-align: left;
  }
  .advice {
    text-align: left;
  }
}
