.join-establishment{
    .search {

        .MuiInputBase-root{
            background: white;
        }
    }
    .search-register {
        margin-top: 15px;
    }

    .fixed-height {
        height: 60vh;
    }

}