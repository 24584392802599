.parkingsContent{

    overflow-y: hidden;

    span.cell-text.cell-text-br {
        white-space: normal;
        display: block;
        margin-top: 0;
        margin-bottom: 0;
    }
    
    .MuiTablePagination-toolbar {
        padding-right: 70px;
    }
    th.MuiTableCell-root.MuiTableCell-head {
        font-size: 14px;
        .box-TableTh-tit{
            display: inline-block;
            height: 1em;
        }
        .box-TableSort{
            position: relative;
            display: inline-block;
            width: 1em;
            height: 1em;
            margin-left: 1em;
            svg.MuiSvgIcon-root.colSort{
                top: calc(50% - 12px);
                right: -4px;
            }
            span.MuiButtonBase-root.MuiTableSortLabel-root{
                right: -5px;
                top: calc(50% - 8px);
            }
        }
        .cell-text-br{
            .box-TableTh-tit,.box-TableSort{height: 2.8em;}
        }
        .cell-text {margin-left: 2em;}
        &.col-left .cell-text {
            margin-left: 0px;
        }
    }    
    
    .MuiTableCell-root.MuiTableCell-body {
        font-size: 13px;
        padding: 5px 1em;
    }
    .columnsContent{
        white-space: nowrap;
        text-align: center;
        button{
            min-width: 0px;
            padding: 7px !important;
            width: 2.7em !important;
            display: inline-block;
            margin: auto;
        }
    }
    .search-box-2 {
        width: 98%;
        text-align: right;
        padding: 7px 1%;
        background-color: #ecebeb;
        .column-left {
            float: left;
        }
        .column-right {
            float: right;
        }
        .filter_field {
            float: left;
            margin-bottom: 1em;
        }
        
        .MuiInputBase-input{
            padding-left: 3px;
            color: #969696;
        }
        label{
            padding-right: 7px;
            padding-top: 8px;
            display: inline-block;
            font-size: 1em;
        }
        
        button{
            background-color: #FFF;
            padding: 5px 10px;
            margin-right: 4px;
            border: 1px solid #c3c3c3;
            color: #969696;
    
            &.filterButton {
                background-color: #439f47;
                color: #FFF;
                border-radius: 12px;
                padding: 3px 0;
                margin-top: 2px;
            }
        }
        .MuiFormControl-root.box-input.date {
            padding: 1px 4px;
            width: 8.5em;
            margin-right: 1em;
        }
        .MuiFormControl-root.box-input, .MuiFormControl-root.input_status {
            border-radius: 6px;
        }
        .input_status{
            width: 9em;
            padding: 0px;
            margin-right: 16px;
            background-color: #FFF;
            border: 1px solid #c3c3c3;
            .MuiSelect-select.MuiSelect-select {
                padding: 5px 2px;
                text-align: left;
            }
        }
    }
    .MuiTab-textColorPrimary {
        color: #43a047;
        background-color: #FFF;
        border-radius: 25px 25px 0px 0px;
        margin-right: 7px;
        font-size: 18px;
        font-family: "Gotham";
    }
    .MuiTab-textColorPrimary.Mui-selected {
        color: #FFF;
        background-color: #43a047;
    }
    .MuiTab-wrapper{
        text-transform: initial;
        padding: 0 1em;
    }
    .MuiFormControl-root.box-input {
        background-color: #FFF;
        border: 1px solid #c3c3c3;
        max-width: 100%;
        width: 14em;
    }
    .MuiInput-underline:before{
        border-bottom: none;
    }
    .MuiSelect-select.MuiSelect-select {
        padding: 9px 1.5em;
        text-align: center;
    }
    .MuiFab-primary{
        position: absolute;
        bottom: 1em;
        right: 1em;
        @media only screen and (max-width: 1366px) {
            right: 0.5em;
            bottom: 2em;
        }        
    }
    
    .MuiTabs-root{
        width: 100%;
    }
}
.full-input {
    width: 90%;
    margin: 9px 5% !important;
}

.box-input-list{
    float: left;
    width: 47%;
    textarea {
        padding: 5px 12px;
        border-radius: 17px;
        width: 228px;
        margin: 10px;
        background-color: #cac8c9;
        font-size: 14px;
        line-height: 32px;
        color: #ffffff;
        font-weight: bold;
        font-family: "Gotham";
        max-width: 100%;
    }
}

.flex-right {
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media only screen and (max-width: 1367px) {
  .parkingsContent{
    .search-box-2 {
        width: 98%;
        text-align: right;
        padding: 7px 1%;
        background-color: #ecebeb;
        font-size: 11px;
        .MuiInputBase-input{
            font-size: 12px;
        }
        label{
            font-size: 1.4em;
            padding-top: 5px;
        }
        .MuiButton-label{
            font-size: 11px;
        }
   }
  }
}
@media only screen and (max-width: 768px) {
    .parkingsContent{
        .search-box-2 {
            .column-left, .column-right {
                float: none;
                text-align: center;
                min-width: 600px;
            }
        }
    }
}

@media only screen and (max-width: 660px) {
    .parkingsContent{
        .search-box-2 {
            .column-left {
                float: none;
                text-align: center;
                min-width: auto;
            }
            .column-rigth {
                float: none;
                width: 99%;
                overflow: auto;
                text-align: center;
            }
            .filter_field {
                min-width: 32%;
            }
            .cols-1{
                .filter_field {
                    width: auto;
                    min-width: 2em;
                    .MuiFormControl-root.box-input.date{
                        width: 7em;
                    }
                    label{
                        display: inline-block;
                    }
                    button.filterButton{
                        margin-top: 3px;
                    }
                }
            }
            .cols-2{
                .filter_field {
                    width: 49%;
                }
            }
            label{
                display: block;
            }

            button.filterButton{
                margin-top: 1.95em;
            }
        }
    }
}
@media only screen and (max-width: 425px) {
    .parkingsContent{
        .search-box-2 {
            .cols-3{
                .filter_field {
                    width: 49%;
                }
                button.filterButton{
                    margin-top: 0px;
                    margin: auto;
                }
            }
        }
    }
}