.listItem {
    background-color: yellow;
    border: 3px solid #000;
    margin-top: 10px;
    margin-bottom: 10px;
}
.purpleDay {
 background-color: purple;
}
.greyDay {
    background-color: grey;
}
.greenDay {
    background-color: rgb(38, 255, 0);
}
.blueDay {
    background-color: blue;
}
.redPoint::after {
    content: "";
    width: 5px;
    height: 5px;
    transform: translate(-119%,219%);
    border-radius: 50%;
    background: red;
}
.greenPoint::after {
    content: "";
    width: 5px;
    height: 5px;
    transform: translate(-119%,219%);
    border-radius: 50%;
    background: yellow;
}
.greyDay {
    background-color: grey;
}
.responsive-calendar {
    /* by setting font-size, all the elements will correspond */
    font-size: 9px !important; /* default to 10px */
}
@media (max-width: 1500px) {
    .responsive-calendar {
    font-size: 8px !important;
    }
}
@media (max-width: 1200px) {
    .responsive-calendar {
    font-size: 7px !important;
    }
}
@media (max-width: 768px) {
    .responsive-calendar {
    font-size: 6px !important;
    }
}
  /* Large screens */
@media (min-width: 2500px) {
    .responsive-calendar {
    font-size: 12px !important;
    }
}
.Calendar__day.-disabled {
    color: var(--cl-color-disabled) !important;
    background: red;
    cursor: default !important;
  }
  .days li.yellowPoint::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 6px;
    width: 6px;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, 178%);
    background-color: green;
}