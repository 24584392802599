.passes {
    .add {
        position: absolute;
        right: 15px;
        top: 0px;
        z-index: 10;
    }
    .loader {
        text-align: center;
        margin-top: 15px;
    }

    .pass-listing{
        height: 54vh;
        padding: 0;
    }
    .tabs-container{
        position: relative;
    }

    @media only screen and (max-width: 960px) {
        .pass-listing{
            height: auto;
        }
    }
}