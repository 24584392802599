.notifications {
  .MuiAvatar-root.MuiAvatar-circle.MuiAvatar-colorDefault {
    background-color: transparent;
    color: #4CAF50;

    .MuiSvgIcon-root {
      width: 1.8em;
      height: 1.8em;
    }
  }
}

.noRow {
  text-align: center;
  color: #767676;
}

.fixedList {
  height: 74vh;
}

@media only screen and (max-width: 960px) {
  .fixedList {
    height: auto;
    max-height: 74vh;
  }
}
