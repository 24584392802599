.nav-profile {
    display: block !important;
    margin-top: 20px !important;
    .avatar {
        .MuiAvatar-root{
            width: 55px;
            height: 55px;
            margin: auto;
        }
        margin-bottom: 5px;
    }
}
.nav-profile.hidden {
    opacity: 0;
}

.nav-profile.MuiListItem-gutters {
    justify-content: center;

}