.AreasContent{
    
  h1.title {
      font-size: 22px;
  }
  .search-box .MuiInputBase-input {
      font-size: 15px;
      padding: 6px;
  }
  th.MuiTableCell-root.MuiTableCell-head {
      font-size: 14px;
  }
  .MuiTableCell-root {
      font-size: 13px;
      padding: 5px;
  }
  span.MuiButtonBase-root.MuiTableSortLabel-root,
  svg.MuiSvgIcon-root.colSort {
      right: 0px;
  }
  .MuiTablePagination-toolbar {
      padding-right: 75px;
  }
  .column-area-name {
    width: 25%;
  }
  .column-area-description {
    width: 30%;
  }
  .column-area-quantity {
    width: 20%;
  }
}