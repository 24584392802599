.wrapper {
    width: 23em;
    background: #fff;
  }
  .wrapper header {
    display: flex;
    align-items: center;
    padding: 10px 0px 0px;
    justify-content: center;
  }
  header .icons {
    display: flex;
  }
  header .icons span {
    height: 38px;
    width: 38px;
    margin: 0 1px;
    cursor: pointer;
    color: #878787;
    text-align: center;
    line-height: 38px;
    font-size: 1.5rem;
    user-select: none;
    border-radius: 50%;
  }
  .icons span:last-child {
    margin-right: -10px;
  }
  header .icons span:hover {
    background: #f2f2f2;
  }
  header .current-date {
    font-size: 1.09rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, Helvetica, sans-serif;
    color: #4caf50;
    font-weight: 600;
  }
  .calendar {
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .calendar ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    text-align: center;
    padding: 0px;
  }
  .calendar .days {
    margin-bottom: 20px;
    padding: 0px;
  }
  .calendar li {
    color: #333;
    width: calc(100% / 7);
    font-size: 1.07rem;
  }
  .description {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, Helvetica, sans-serif;
    padding-left: 2em;
    padding-right: 2em;
  }
  .parrafo {
    white-space: pre;
    font-size: 0.8em;
  }
  .message {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, Helvetica, sans-serif;
    padding-left: 2em;
    padding-right: 2em;
    font-size: 19px;
    font-weight: bolder;
    color: gray;
    padding-bottom: 2em;
  }
  .title {
    font-weight: 600;
    font-size: 17px;
  }
  .calendar .weeks li {
    font-weight: 500;
    cursor: default;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, Helvetica, sans-serif;
  }
  .calendar .days li {
    z-index: 1;
    cursor: pointer;
    position: relative;
    margin-top: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, Helvetica, sans-serif;
  }
  .days li.inactive {
    color: #aaa;
  }
  .days li.active {
    color: #fff;
  }
  .days li::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 40px;
    width: 40px;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .days li.active::before {
    background: #127c14;
  }
  .days li.selected::before {
    background: #4caf50;
    color: #fff;
  }
  .days li.selected {
    font-weight: bold;
    color: #fff;
  }
  .greenSelected::before {
    background: #4caf50;
    color: white;
  }
  .selectedPast::before {
    background: #aaa;
  }
  
  .selected::before {
    background: rgb(221, 214, 10);
  }
.days li.redPoint::before {
    pointer-events: none;
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 6px;
    width: 6px;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, 373%);
    background-color: red;
  }
  .days li.greenPoint::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 6px;
    width: 6px;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, 373%);
    font-weight: normal;
    background-color: #4caf50;
  }
  .days li.greenPoint {
    font-weight: normal;
  }
  .days li.yellowPoint::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 6px;
    width: 6px;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, 178%);
    background-color: yellowgreen;
  }
  .days li.selectedPoint::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 6px;
    width: 6px;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, 373%);
    font-weight: normal;
    background-color: green;
  }

  .detail {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, Helvetica, sans-serif;
    padding-left: 2em;
    padding-right: 2em;
    font-size: 11px;
    margin-top: 2em;
  }

  .greenReserve {
    height: 6px;
    width: 6px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: #4caf50;
    margin-left: 0px;
    margin-top: 29px;
  }

  .redReserve {
    height: 6px;
    width: 6px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    background-color: red;
    margin-left: 0px;
    margin-top: 12px;
  }