.root {
    height: 100vh;
    width: auto;
    display: "flex";
    flex-direction: "column";
    background-color: "#eeeee4";
}
.Calendar-disabled {
    color: var(--cl-color-disabled) !important;
    background: red;
    cursor: default !important;
}
.box {
    border-radius: "10px !important";
    padding: "20px";
    flex-grow: "1";
}
.main {
    text-align: center;
    display: flex !important;
    align-items: center;
    min-height: 100vh;
}
.Calendar {
    width: 46em;
}

.purpleDay {
 background-color: purple;
}
.grayDay:not(.-disabled):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-color: gray;
  pointer-events: none;
}
.greenSelected::after {
    background-color: rgb(53, 143, 7);
    content: "";
    width: 5px;
    height: 5px;
    transform: translate(-119%,219%);
    border-radius: 50%;
}
.blueDay {
    background-color: blue;
}
.redPointt::after {
    content: "";
    width: 5px;
    height: 5px;
    transform: translate(-119%,219%);
    border-radius: 50%;
    background: red;
    pointer-events: none;
}
.greenPoint::before {
    content: "";
    width: 5px;
    height: 5px;
    transform: translate(-119%,450%);
    border-radius: 50%;
    background: green;
}
.greyDay {
    background-color: grey;
}
.selectedPast::before {
    background: #adaab6;
    color: white
  }
.selectPast {
    background-color: #4caf50;
}
.calendar .days li .selectedPast {
    color: white
}
  .greenSelected::before {
    background: #4caf50;
  }
.responsive-calendar {
    /* by setting font-size, all the elements will correspond */
    font-size: 9px !important; /* default to 10px */
}
@media (max-width: 1500px) {
    .responsive-calendar {
    font-size: 8px !important;
    }
}
@media (max-width: 1200px) {
    .responsive-calendar {
    font-size: 7px !important;
    }
}
@media (max-width: 768px) {
    .responsive-calendar {
    font-size: 6px !important;
    }
}
  /* Large screens */
@media (min-width: 2500px) {
    .responsive-calendar {
    font-size: 12px !important;
    }
}
.days li.yellowPoint::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 6px;
    width: 6px;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, 178%);
    background-color: rgb(50, 174, 205);
  }