.profile {
    text-align: center;
    .MuiAvatar-root {
        width: 120px;
        height: 120px;
        margin: auto;
    }

    .add {
        text-align: center;
        display: block;
    }
}