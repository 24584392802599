.verification {
    margin-top: 3%;
    text-align: center;
    h2 {
        color: #33b14b;
        font-weight: 600;
        font-size: 23px;
         margin-top: 3em;
    }
    p.my-4-2 {
        margin: 4em auto 2em; 
    }
    
    .MuiInputBase-root{
        width: 35px;
        height: 35px;
        margin: 5px;
        font-weight: bold;
        padding: 10px 0;
        input {
            padding: 5px;
            text-align: center;
        }
    }
    .field{
        flex: 1 !important;
        margin-top: 10px;
        .MuiTextField-root{
            width: 100%;
        }
        .MuiInputBase-root{
            width: 100%;
            input {
                text-align: left;
                padding-top: 14px;
                font-weight: 400;
            }
        }
    }
    .send {
        display: block;
        text-align: center;
    }

    .info {
        margin-bottom: 10px;
    }
    
    .option {
        display: block;
        margin-top: 15px;
        color: rgb(66, 66, 66);
    }

    .cancel {
        margin-top: 60px;
        color: darkred;
    }
    .mt-0{
        margin-top: 0px !important;
    }
    button.add {
        width: 200px;
        margin: 1em auto;
    }
    button.addSMS {
        color: #3e9442;
        background-color: #FFF;
        font-weight: bold;
        border: 1px solid #3e9442;
        width: 200px;
    }
    
    .MuiInputLabel-formControl {
    top: -5px;
    left: 4px;
    }
    .loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #a5a5a547;
        z-index: 99;
    }
    .MuiCircularProgress-root.submit {
        margin-top: 27vh;
    }
}