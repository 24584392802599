.RoomReservationContent{
    
  h1.title {
      font-size: 22px;
  }
  .search-box .MuiInputBase-input {
      font-size: 15px;
      padding: 6px;
  }
  th.MuiTableCell-root.MuiTableCell-head {
      font-size: 14px;
  }
  .MuiTableCell-root {
      font-size: 13px;
      padding: 5px;
  }
  span.MuiButtonBase-root.MuiTableSortLabel-root,
  svg.MuiSvgIcon-root.colSort {
      right: 0px;
  }
  .MuiTablePagination-toolbar {
      padding-right: 75px;
  }

  .columnsContent{
    button{
        min-width: 0px;
        padding: 7px !important;
        width: 2.7em !important;
        display: inline-block;
        margin: auto;
    }
  }
  .MuiFormControl-root.seach-dropdown{
    float: right;
    display: block;
  }
  .MuiInput-underline:before{
    content: none;
  }
  .MuiFormControl-root.seach-dropdown{

    .MuiInputBase-fullWidth {
      width: 20em;
      float: right;
      padding: 2px 7px;
      border: 1px solid #e6e6e6;
      border-radius: 6px;
      background-color: #FFF;
      margin-top: 0px;
      margin-right: 0px;
    
      .MuiSelect-select.MuiSelect-select {
        padding-right: 24px;
        width: 100%;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
        font-family: "Gotham";
        border-color: #dedede;
      }
    }
  }
  .cell-status{
    font-weight: bold;
    font-family: "Gotham";
    white-space: nowrap;
    float: left;
    margin-right: 1em;
    .MuiSvgIcon-root{
      float: left;
      margin-top: -3px;
      margin-right: 3px;
    }
    &.primary {
      color: #389a2e;
    }
  }

  .file-download{
    clear: both;
    margin-bottom: 1em;
    width: 195px;
    span{
      max-width: calc( 100% - 68px);
      float: left;
      margin-left: 5px;
      word-wrap: break-word;
    }
    a{
      float: left;
      padding: 0 2px;
      margin-top: -4px;
      background-color: #fafafa;
    }
  }
  .addNewItem{
    position: absolute;
    bottom: 1em;
    right: 3em;
    @media only screen and (max-width: 1366px) {
        bottom: 3em;
    }
  }
  /*****************************************/
  /***    FORM RESERVATION  ***************/
  .MuiFormControl-root.box-input {
    border: 2px solid #cac8c9;
    border-radius: 7px;
    padding: 8px;
    width: 96%;
    height: 50px;
    font-size: 11px;
  }    
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    font-family: Gotham;
  }   
  label.MuiFormLabel-root.MuiInputLabel-root.dropdown.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    margin: 7px;
  }      
  .MuiFormControl-root.box-input.full {
    margin: 1em auto;
    display: table;
  }
  .MuiFormControl-root.box-input.table {
    display: table;
  }
  .MuiGrid-spacing-xs-3 {
    margin: -10px -10px;
    width: 100%;
  }
  .pr-0{
    padding-right: 0 !important;
    width: 100% !important;
    min-width: initial;
  }
  label.MuiInputLabel-root.MuiInputLabel-formControl {
    margin-top: -16px;
  }
  .date label.MuiInputLabel-root.MuiInputLabel-formControl {margin-top: -16px;}
  label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink.MuiFormLabel-filled,
  label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink.Mui-focused {
    margin-top: initial;
    width: 130%;
  }
  .MuiFormControl-root.box-input.full textarea {
    border: none;
    padding: 10px;
    min-height: 6em;
    width: 96%;
    display: block;
    font-family: "Gotham";
  }
  .dzu-dropzone {
    overflow: hidden;
    min-height: 9em;
  }
  label.dzu-inputLabel {
    color: #cac8c9;
    div{
      text-align: center;
      div{
        font-size: 14px;
      }
    }
  }

  .form-box{
    margin-top: -13px;
  }
  h2.form-title{
    background-color: #2fa537;
    border-radius: 10px;
    font-size: 11px;
    font-size: 17px;
    line-height: 24px;
    color: #ffffff;
    font-family: "Gotham";
    padding: 1em;
  }
  h4{
    font-family: Gotham;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-weight: 100;
  }
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 {
    font-family: Gotham;
  }
  .MuiGrid-root.text-right {
    text-align: right;
  }
  .MuiGrid-root.text-right.MuiGrid-item.MuiGrid-grid-xs-6 a {
    margin-right: 1em;
  }
  .Schedule-layout {
    padding: 1em;
    padding-left: 0;
    margin-left: -14px;
    font-family: Gotham;
    div{
      display: inline-block;
      margin-bottom: 0.5em;
      font-size: 1em;
    }
    .circle{
      width: 11px;
      height: 11px;
      border-radius: 50%;
      border: 1px solid;
      display: inline-block;
      margin: 0 5px 0 15px;
    }
  }

  .current{
    background-color: #b1ffff;
  }
  .pending{
    background-color: #ffc926;
  }
  .approved{
    background-color: rgba(255, 0, 0, 0.5);
  }

  .table-schedule .thead.MuiTableHead-root{
    background-color: #dedede;
  }

  .table-schedule th.MuiTableCell-root.MuiTableCell-head {
    color: #696969;
    font-size: 14px;
    padding: 3px;
    font-weight: 400;

  }
  .table-schedule .MuiTableCell-root.MuiTableCell-body {
    padding: 0px;
    border: none;
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
    text-align: center;
    font-family: "Gotham";
    height: 1px;
  }
  td.MuiTableCell-root.MuiTableCell-body.CellSchedule.borded{
    border-bottom: solid 1px #e0e0e0;
  }
  .table-schedule th.MuiTableCell-root.MuiTableCell-body {
    width: 3em;
    border-bottom: solid 1px #e0e0e0;
  }
  .table-schedule tr.MuiTableRow-root.MuiTableRow-head {
    background-color: #ececec;
  }
  .table-schedule tr.MuiTableRow-root.MuiTableRow-head th{
    color: #000000 !important;
    width: 9em;
    white-space: pre-wrap;
    font-family: "Gotham";
  }
  .table-schedule tr.MuiTableRow-root.MuiTableRow-head th:nth-child(1) {
    max-width: 25px;
  }
  .dzu-submitButtonContainer{
    display: none;
  }
  label.MuiFormControlLabel-root {
    margin: 0;
  }
  .button-group{
    clear: both;
    overflow: auto;
  }
  
  .MuiInputBase-input{
    min-width: 76%;
  }
  .MuiTablePagination-toolbar .MuiSelect-select{
	min-width: 16px;
  }
  .linkLayoutInformationUrl{
    text-align: center;
    margin-bottom: 10px;
    a{
      color: #77aaff;
      text-decoration: underline;
      font-size: 18px;
      cursor: pointer;
    }
    .buttom-reservation{
      background-color: #77aaff;
      width: auto;
      height: auto;
      padding: 8px;
      border-radius: 3px;
      font-size: 16px;
      float: none;
    }
  }
  .link_tyc{
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
      color: #000000;
    }
    a{
      text-transform: lowercase;
      text-decoration: underline;
      color: #77aaff;
      cursor: pointer;
    }
  }
  .text_other_files_RoomReservation {
    font-size: 13px;
    color: #ffffff;
    font-family: "Gotham";
    background-color: #4d4d4d;
    padding: 14px;
    border-radius: 0 0 15px 15px;
  }
  
  .sinMobiliario{padding-top: 1.35em !important;}

  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    background-color: #ffff;
    padding-left: 8px;
  }
  button.MuiButtonBase-root.MuiIconButton-root {
    padding: 0;
  }
  .owner {
    border: 2px solid #cac8c9;
    border-radius: 7px;
    padding: 12px;
    margin-top: 14px;
      h3.reservation_owner {
        font-size: 17px;
        font-family: gotham;
        color: rgba(0, 0, 0, 0.54);;
        text-transform: uppercase;
        margin: 0;
        span {
          font-family: gotham;
          padding-left: 7px;
          color: #444444;
          text-transform: none;
        }
      }
    }

 
  /* Responsive  */
  @media only screen and (max-width: 1300px) {
    .search-box {
      width: 12em;
      height: 33px;
      margin-top: -2px;
    }
    .MuiFormControl-root.seach-dropdown .MuiInputBase-fullWidth{
      height: 40px;
      width: 16em;
    }
  }
  @media only screen and (max-width: 1100px) {
    .MuiFormControl-root.seach-dropdown .MuiInputBase-fullWidth{
      width: 12em;
    }
    .search-box {
      width: 9em;
    }
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
      font-size: 1em;
    }
    .Schedule-layout div{
      display: block;
      white-space: nowrap;
      
    }
  }
 
  @media only screen and (max-width: 962px) {
    .search-box {
      float: right;
      clear: initial;
      margin: -1px 8px;
    }
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
      font-size: 1em;
    }
  }
              
  @media only screen and (max-width: 704px) {
    .header-box{
      height: 5em;
    }
    h1.title {
      width: 100%;
    }
    .filter-box{
      width: 100%;
      clear: both;
      margin: 0 16px;
    }
    .search-box,
    .MuiFormControl-root.seach-dropdown{
      width: 45%;
      float: left;
      margin: 0;
    }
    .search-box {
      height: 33px;
    }
    .MuiFormControl-root.seach-dropdown .MuiInputBase-fullWidth{
      width: 100%;
    }
    input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      font-size: 0.90em;
      padding-top: 10px;
    }
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
      font-size: 1em;
    }
  }

  @media only screen and (max-width: 375px) {
    .header-box {
      height: 9em;
    }
    .search-box, 
    .MuiFormControl-root.seach-dropdown{
      width: 90%;
      float: none;
      display: block;
      margin: 7px auto;
    }
    .MuiFormControl-root.seach-dropdown{
      height: 2.3em;
      width: 95%;
      margin-left: 5%;
    }
    .filter-box{
      margin: 0px !important;
    }
  }
  
}
.summary{
  margin-bottom: 1em;
  label{
    font-family: Gotham;
    color: #0c0c0c;
    font-size: 1em;
    display: inline-block;
    margin-right: 8px;
  }
  span.danger {
    color: #ff0000;
  }
}

  button.buttom-reservation {
    width: 76%;
    margin: 1em 17%;
    padding: 12px;
    border-radius: 17px;
    float: right;
    width: 228px;
    margin: 10px;
    height: 42px;
    padding: 0;
    background-color: #cac8c9;
    font-size: 17px;
    line-height: 24px;
    color: #ffffff;
    font-weight: bold;
    font-family: "Gotham";
    &:hover{
      color: #000000;
      background-color: #cac8c9;
    }
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.buttom-reservation.MuiButton-textPrimary{
    background-color: #2fa537 !important;
    color: #ececec;
    :hover{
      color: #000000;
    }
  }

  .alertDialog{
    .MuiDialogTitle-root{
      padding: 16px 16px 0 !important;
    }
  }

  .pdfDialog{
    .MuiPaper-root{
      height: 95vh;
    }
    .MuiDialogContent-root {
      overflow-y: hidden;
    }
    iframe {
      border: 0;
      width: 100%;
      height: 100%;
    }
  } 
  