@font-face {
    font-family: "Gotham";
    src: url("../fonts/Gotham-Bold.otf");
}    
@font-face {
    font-family: "Gotham-Light";
    src: url("../fonts/Gotham-Light.otf");
}    
body{
    font-family: Gotham;
}
.MuiGrid-container.MuiGrid-root .header-card {
    margin: 0 !important;
    padding-bottom: 2px !important;
    font-size: 18px;
    text-align: center;
    color: rebeccapurple !important;
}
.MuiGrid-root.MuiGrid-container .card {
    margin-top: 0;
}

.MuiGrid-root.MuiGrid-container .card.rounded {
    border-radius: 5px;
    background-color: #FAFAFA;
}

.MuiGrid-root.MuiGrid-container .single-card-header {
    margin: -52px 0 0 0 !important;
    border-radius: 10px 10px 0 0 !important;
    box-shadow: none !important;
}

.MuiGrid-root.MuiGrid-container .single-card {
    border-radius: 0px 0px 10px 10px !important;
}
.info {
    .MuiSvgIcon-root {
        color: #205F26;
    }
}
.center {
    text-align: center !important;
}

.right {
    text-align: right !important;
}

.left {
    text-align: left !important;
}

.bold {
    font-weight: bold;
} 

.dialog-title-button {
    position: absolute !important;
    top: 5px;
    right: 10px;

    .MuiButtonBase-root.MuiIconButton-root {
        color: white;
    }
}

.navbar-icon{
    top: 0;
    width: 18px !important;
    height: 18px !important;
    display: inline-block;
    position: relative;
    margin-right: 4px;
    vertical-align: middle;
}

.MuiDialogTitle-root {
    background: #205F26;
    color: white;
}

.dialog-buttons{ 
    justify-content: center !important;
    div.loader {
        position: absolute;
        right: 16px;
    }
}

.copy-button{
    display: inline-block;
}

form {
    display: contents;
}
.MuiDialog-paperFullWidth{
    overflow: hidden;
}

.MuiTooltip-popper{
    text-align: center;
    min-width: 150px !important;
}

.map {
    height: 300px;
}

.map-marker {
    margin-left: -13px;
    margin-top: -80px;
}

.content-box{
    box-sizing: border-box;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.header-box{
    background-color: #FFF;
    position: relative;
    padding: 10px;
    margin-bottom: 3px;
    border-radius: 15px 15px 0px 0px;
}
.body-box{
   flex-grow: 1;
   padding: 10px;
   background-color: #FFF;
   border-radius: 0px 0px 15px 15px;
}
.clearfix {
    clear: both;
    width: 100%;
}
.flex {
    display: flex;
}
.pull-left{
    float: left;
}
.pull-right{
    float: right;
}
.search-box{
    width: 20em;
    float: right;
    padding: 2px 7px;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    background-color: #FFF;
    margin-top: 0px;
    margin-right: 7px;
}
.search-box .MuiInputBase-input{
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    font-family: "Gotham";
    border-color: #dedede;
}
.MuiSelect-select:focus {
    border-radius: 0;
    background-color: transparent !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #dedede;
opacity: 1; /* Firefox */
}
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #dedede;
}

::-ms-input-placeholder { /* Microsoft Edge */
color: #dedede;
}

.MuiButton-root.Mui-disabled {
    opacity: 0.55;
}
.full-width{width: 100%;}

h1.title {
    margin: 0 auto;
    font-size: 26px;
    color: #389a2d;
    font-family: "Gotham";
    float: left;
}

/*****************************************/
/* Table  */
th.MuiTableCell-root.MuiTableCell-head {
    white-space: nowrap;
    text-transform: uppercase;
    position: relative;
    font-size: 16px;
    color: #205f26;
    font-weight: bold;
    font-family: "Gotham";
    text-align: center;
}
th.MuiTableCell-root.MuiTableCell-head.fist-col {
    min-width: 11.7em;
    text-align: left;
}
td.MuiTableCell-root.MuiTableCell-body {
    text-align: center;
    font-family: "Gotham";
}
td.MuiTableCell-root.MuiTableCell-body.fist-col,
.MuiTableCell-root.col-left {
    text-align: left !important;
}
span.MuiButtonBase-root.MuiTableSortLabel-root {
    position: absolute;
    right: 10px;
    
}
svg.MuiSvgIcon-root.colSort {
    margin-left: 0px;
    transform: rotate(90deg);
    right: 10px;
    color: #cac8c9;
    position: absolute;
}
.colSort {
    margin-left: 0px;
    font-size: 18px;
    color: #cac8c9;
    position: absolute;
    right: 10px;
}
span.cell-text {
    margin: 15px;
}
.col-left span.cell-text {
    margin-left: 0px;
}
.MuiTable-root {
    background-color: #fafafa;
}
.fixed-height {
    height: 70vh;
}

.expand-height {
    height: 100%;
}

.scroll{
    overflow: auto;
}
/*****************************************/
.loading_diag{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    text-align: center;
    padding-top: 41vh;
    .MuiDialog-paperWidthSm {
        width: 43px;
        height: 43px;
        overflow: hidden;
        background-color: transparent;
        box-shadow: none;
    }
}

.formPicker {
    input.MuiInputBase-input.MuiOutlinedInput-input {
        max-width: 4em;
        text-align: center;
    }
    div#mui-component-select-month {
        width: 6em;
    }
    fieldset.MuiFormControl-root.formControl {
      margin: 0 7px;
    }
    .MuiDialogContent-root:first-child {
        padding-top: 2em;
    }
}
p.MuiFormHelperText-root.text-error {
    color: #e44141;
    padding-left: 8px;
}

.titleDialog {
    position: relative;
    
    .iconRight {
        position: absolute;
        right: 5px;
        top: 0px;
        height: 100%;
        display: flex;
        align-items: center;
    }
}
    
@media only screen and (max-width: 960px) {
    .search-box {
        width: 80%;
        float: left;
        margin: 0.5em;
        clear: both;
    }
}
                            
@media only screen and (max-width: 960px) {
    .header-box,.body-box{
        padding: 7px;
    }
}

.passwordValidation.forgotPassword {
    margin: 15px 0px;
}

.passwordValidation {
    margin: 0px;
    text-align: left;
    font-size: 15px;
}
.passwordValidation p {
    margin: 0 5px;
}
.passwordValidation p {
    color: red;
}
.passwordValidation p.success {
    color: green;
}